.iItem {
    font-size: 20px;
    line-height: 1;
    &:hover {
      opacity: 0.5;
    }
    &.notify {
      padding: 5px 0 5px 5px;
      position: relative;
    }
    &.menu {
      padding: 5px 5px 5px 0;
    }
  }