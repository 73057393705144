.center {
  max-width: 1920px;
  padding: 0 140px;
  margin: auto;

  @media (max-width: 1600px) {
    padding: 0 120px;
  }
  @media (max-width: 1440px) {
    padding: 0 100px;
  }
  @media (max-width: 1200px) {
    padding: 0 80px;
  }

  @media (max-width: 1024px) {
    padding: 0 60px;
  }

  @media (max-width: 820px) {
    padding: 0 40px;
  }

  @media (max-width: 740px) {
    padding: 0 24px;
  }
}
