.list {
    position: relative;
}

.gridLoading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--default-bg);
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s, visibility 1ms 0.4s;
}

.gridLoading>div {
    transform: translateX(-50% -50%);
}

.gridLoadingShow {
    visibility: visible;
    opacity: 0.7;
    transition: opacity 0.4s 1ms, visibility 1ms;
}

.loadMoreSpinner {
    background-color: var(--load-more-bg);
    position: relative;
    height: 80px;
    margin-top: 24px;
    display: flex;
}