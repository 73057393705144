.text {
  font-style: normal;
  font-weight: 400;

  &.f18 {
    font-size: 18px;
    @media (max-width: 1550px) {
      font-size: 17px;
    }
    @media (max-width: 1400px) {
      font-size: 16px;
    }
    @media (max-width: 1200px) {
      font-size: 15px;
    }
  }

  &.f16 {
    font-size: 16px;
    @media (max-width: 1550px) {
      font-size: 15px;
    }
  }

  &.f14 {
    font-size: 14px;
    @media (max-width: 1550px) {
      font-size: 13px;
    }
    @media (max-width: 820px) {
      font-size: 12px;
    }
  }
}
