.toolTip{	
	position: absolute;
	left: 50%;
	opacity: 0;
	visibility: hidden;
    line-height: 1;
	transform: translateY(5px);
}
*:hover > .toolTip{
	opacity: 1;
	visibility: visible;
	transform: translateY(0px);
	transition: visibility 0.01s, opacity 0.3s 0.01s, transform 0.3s 0.01s;
}
.toolTip .center{
	position: relative;
	background: var(--tooltip-bg);
	left: -50%;
    line-height: 1;
	color: var(--tooltip-text);
	padding: 10px 15px;
    white-space: nowrap;
	text-transform: none;
	font-weight: normal;
	font-size: 12px;
	border-radius: 5px;
}
.toolTip .scroller {
	max-height: 210px;
	overflow: auto;
	overflow-x: hidden;
	line-height: 1.2;
}
.toolTipIcon {
	position: absolute;
	left: 0;
	color: var(--tooltip-bg);
	font-size: 18px;
    margin-left: -5px;
}
.top {
	margin-bottom: 8px;
	bottom: 100%;
}
.top .toolTipIcon {
	bottom: -11px;
}
.bottom {
	top: 100%;
	margin-top: 11px;
}
.bottom .toolTipIcon {
	top: -11px;
}
.right .center {
	left: -20px;
}
.toolTip .center a {
	border-bottom: 1px solid var(--tooltip-text);
}

@media (max-width:400px) {
	.loOverFlow.toolTip .center{
		left: -78%;
	}
}