.editor {
  h1 {
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 1.1;

    @media (max-width: 1550px) {
      font-size: 38px;
    }
    @media (max-width: 1400px) {
      font-size: 36px;
    }
    @media (max-width: 1200px) {
      font-size: 34px;
    }
    @media (max-width: 820px) {
      font-size: 32px;
    }
    @media (max-width: 580px) {
      font-size: 30px;
    }
  }
  h2 {
    font-size: 38px;
    font-weight: 700;
    line-height: 1.1;

    @media (max-width: 1550px) {
      font-size: 34px;
    }
    @media (max-width: 1400px) {
      font-size: 32px;
    }
    @media (max-width: 1200px) {
      font-size: 30px;
    }
    @media (max-width: 820px) {
      font-size: 28px;
    }
  }
  h3 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.1;

    @media (max-width: 1550px) {
      font-size: 30px;
    }
    @media (max-width: 1400px) {
      font-size: 28px;
    }
    @media (max-width: 1200px) {
      font-size: 26px;
    }
    @media (max-width: 820px) {
      font-size: 24px;
    }
  }
  h4 {
    font-size: 32px;
    font-weight: 700;

    @media (max-width: 1550px) {
      font-size: 28px;
    }
    @media (max-width: 1400px) {
      font-size: 26px;
    }
    @media (max-width: 1200px) {
      font-size: 24px;
    }
    @media (max-width: 820px) {
      font-size: 22px;
    }
  }
  h5 {
    font-size: 28px;
    line-height: 1.1;

    @media (max-width: 1550px) {
      font-size: 26px;
    }
    @media (max-width: 1400px) {
      font-size: 24px;
    }
    @media (max-width: 1200px) {
      font-size: 22px;
    }
    @media (max-width: 820px) {
      font-size: 20px;
    }
  }
  h6 {
    font-size: 24px;
    line-height: 1.1;

    @media (max-width: 1550px) {
      font-size: 22px;
    }
    @media (max-width: 1400px) {
      font-size: 20px;
    }
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 820px) {
      font-size: 17px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    @media (max-width: 1440px) {
      margin-top: 20px;
    }
    @media (max-width: 1200px) {
      margin-top: 16px;
    }
  }

  h1 + h1,
  h2 + h2,
  h3 + h3,
  h4 + h4,
  h5 + h5,
  h6 + h6 {
    margin-top: 16px;
    @media (max-width: 820px) {
      margin-top: 8px;
    }
  }

  &.boldHighlightHtags h1 strong,
  &.boldHighlightHtags h2 strong,
  &.boldHighlightHtags h3 strong,
  &.boldHighlightHtags h4 strong,
  &.boldHighlightHtags h5 strong,
  &.boldHighlightHtags h6 strong {
    color: var(--tab-title-color);
  }

  em {
    font-style: italic;
  }

  p {
    font-size: 16px;
    margin-top: 24px;
    letter-spacing: 0.5px;
    @media (max-width: 820px) {
      margin-top: 16px;
      font-size: 15px;
    }
    @media (max-width: 580px) {
      font-size: 14px;
    }
  }

  a {
    text-decoration: underline;
    text-decoration-color: var(--editor-link-border);
    text-underline-offset: 5px;

    &:hover {
      text-decoration-color: transparent;
    }
  }

  &.secA a {
    text-decoration-color: var(--editor-link-border-secondary);

    &:hover {
      text-decoration-color: transparent;
    }
  }

  & > *:first-child {
    margin-top: 0;
  }
  ul {
    margin-left: 15px;
    margin-top: 24px;
    @media (max-width: 820px) {
      margin-top: 16px;
    }
    li {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 1.5;
      list-style: disc;
      padding-top: 16px;

      &:first-child {
        padding-top: 0;
      }

      @media (max-width: 820px) {
        font-size: 15px;
        padding-top: 8px;
      }
      @media (max-width: 580px) {
        font-size: 14px;
      }
    }
    li:not(:first-child) {
      margin-top: 5px;
    }

    ol, ul {
      margin-top: 16px;
      padding-left: 15px;
      @media (max-width: 820px) {
        margin-top: 8px;
      }
    }
  }
  ol {
    margin-top: 24px;
    @media (max-width: 820px) {
      margin-top: 16px;
    }
    padding: 0;
    li {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 1.5;
      list-style: inside decimal;
      padding-top: 16px;

      &:first-child {
        padding-top: 0;
      }

      @media (max-width: 820px) {
        font-size: 15px;
        padding-top: 8px;
      }
      @media (max-width: 580px) {
        font-size: 14px;
      }
    }
    ol, ul {
      margin-top: 16px;
      padding-left: 15px;
      @media (max-width: 820px) {
        margin-top: 8px;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 24px;

    @media (max-width: 820px) {
      margin-top: 16px;
    }
  }
  table,
  th,
  td {
    border: 1px solid var(--special-border);
    border-collapse: collapse;
    text-align: left;
    padding: 8px;
  }
}
