.formGroup {
  .label {
    padding-bottom: 8px;
  }
}
.inputfield {
  position: relative;
  border: 1px solid var(--input-border);
  // border-radius: 4px;

  .textarea {
    padding: 16px;
  }

  .inputfieldContainer {
    .otpInput,
    .textbox {
      color: inherit;
      background: transparent;
      border: none;
      width: 100%;
      letter-spacing: 0.25px;
      color: var(--default-text);
      padding: 16px;

      @media (max-width: 820px) {
        padding: 10px;
      }

      &.hasIcon {
        padding: 16px 34px 16px 16px;
      }

      &::-webkit-input-placeholder {
        font-size: 14px;
        color: var(--placeholder);
      }
      &:disabled {
        opacity: 0.5;
        background-color: var(--input-bg);
      }
    }
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
  }
}

:global(.maximes){
  .inputfield .inputfieldContainer .textbox{
    &:disabled{
      background-color: var(--secondary-white);
    }
  }
}

.helpBlock {
  color: var(--input-required);
  font-size: 12px;
  font-weight: 600;
  padding-top: 5px;
  text-align: right;
}

.dropdown {
  position: relative;
  border: 1px solid var(--input-border);
  // border-radius: 4px;
  overflow: hidden;

  .selectBox {
    width: 100%;
    padding: 14px 35px 14px 16px;
    background-color: transparent;
    @media (max-width: 820px) {
      padding: 10px 35px 10px 16px;
    }
  }

  &.disabled {
    .selectBox {
      pointer-events: none;
      opacity: 0.5;
      background-color: var(--input-bg);
    }
  }

  .dropdownIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
    color: var(--default-text);
  }
}

.inputError .dropdownSection,
.inputError .customDropdown,
.inputError .inputfield,
.inputError .dropdown {
  border-color: var(--input-required);
}

.inputValid .dropdownSection,
.inputValid .customDropdown,
.inputValid .inputfield,
.inputValid .dropdown {
  border-color: var(--input-valid);
}

.inputValid .dropdownIcon,
.inputValid .icon {
  color: var(--input-valid);
}

.button {
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding: 16px 32px;
  font-weight: 500;
  // border-radius: 8px;
  border: 1px solid;
  color: var(--white);
  transition: all 0.4s;
  line-height: 1;
  background-color: var(--btn-primary);
  border-color: var(--btn-primary);
  color: var(--btn-text);
  text-align: center;
  &:hover {
    background-color: transparent;
    color: var(--btn-primary);
  }

  &.secondary {
    background-color: var(--btn-secondary);
    color: var(--default-text);
    &:hover {
      background-color: var(--btn-primary);
      color: var(--secondary-pink);
    }
  }

  &.golden {
    background-color: var(--btn-gold);
    color: var(--btn-gold-text);
    &:hover {
      background-color: transparent;
      color: var(--btn-gold);
      border-color: var(--special-border);
    }
  }

  &.solid {
    background-color: var(--btn-solid);
    color: var(--btn-solid-text);
    &:hover {
      background-color: var(--btn-solid-text);
      color: var(--btn-solid);
      border-color: var(--btn-solid-text);
    }
  }

  &.hasIcon {
    padding: 8px 15px;
    display: flex;
    align-items: center;
  }
  & .icon {
    padding-left: 16px;
  }
  &.disabled,
  &.fetching {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (max-width: 820px) {
    padding: 12px 24px;
  }
}

.otp {
  .inputfield {
    border: none;
  }
  .otpHolder {
    border: 0;
    .otpInputContainer {
      flex-direction: row;
      margin: 0 -5px;
      & > * {
        flex: 1;
        padding: 0 10px;
        border: 1px solid var(--input-border);
        // border-radius: 5px;
        margin: 0 5px;
        @media (max-width: 600px) {
          padding: 0 5px;
        }
      }
    }
    .otpInput {
      width: 100% !important;
      padding: 15px 0 !important;
      font-size: 24px;
      font-size: 30px;
      font-weight: 600;
      @media (max-width: 600px) {
        padding: 5px 0 !important;
      }
    }
    .otpError {
      color: var(--input-error);
      text-align: right;
      padding: 5px 0 5px 0;
    }
  }
}

.listOption {
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
  }
  .option {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 16px;
    gap: 16px;
    &:first-child {
      padding-top: 0;
    }
  }
  .label {
    @media (max-width: 1024px) {
      font-size: 15px;
    }
    @media (max-width: 820px) {
      font-size: 14px;
    }
  }
  .multiOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .loader {
    position: relative;
    overflow: hidden;
    height: 20px;
    width: 100%;
  }
}

.toggle {
  position: relative;
  border-radius: 30px;
  border: 1px solid var(--input-border);
  width: 40px;
  height: 20px;
  &::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    background-color: var(--input-border);
    border-radius: 50%;
    transition: all 0.4s;
  }
  &.active {
    border-color: var(--input-valid);
    &::after {
      margin-left: 18px;
      background-color: var(--input-valid);
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ListCheckboxOption {
  margin: -16px -8px 0;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 420px) {
    justify-content: center;
  }
  .optionWrap {
    width: 16.66%;
    min-height: 200px;
    padding: 16px 8px 0;
    @media (max-width: 1400px) {
      width: 20%;
    }
    @media (max-width: 1050px) {
      width: 25%;
    }
    @media (max-width: 820px) {
      width: 33.33%;
    }
    @media (max-width: 560px) {
      width: 50%;
    }
    @media (max-width: 420px) {
      width: 100%;
      max-width: 215px;
    }
  }
  .option {
    width: 100%;
    height: 100%;
    padding: 24px;
    padding-top: 16px;
    border: 1px solid var(--checkbox-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 0.4s, border-color 0.4s;
    &.skeleton {
      border: none;
      padding: 0;
    }
    &.active {
      background-color: var(--active-checkbox-bg);
      border-color: var(--active-checkbox-border);
      color: var(--active-checkbox-color);
      .checkbox {
        .box {
          border-color: inherit;
        }
      }
    }
    .checkbox {
      .box {
        transition: border-color 0.4s;
        border-color: var(--checkbox-border);
      }
    }
  }
  .label {
    margin: auto;
    text-align: center;
    font-weight: 600;

    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 820px) {
      font-size: 14px;
    }
  }
  .loader {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}

.datepicker {
  .inputfield {
    .textbox {
      padding: 10px 16px 10px 16px;
      @media (max-width: 820px) {
        padding: 6px 16px 6px 16px;
      }
    }
  }
  :global(.react-datetime-picker__wrapper) {
    border: none;
  }
  :global(.react-calendar__navigation button:enabled:hover),
  :global(.react-calendar__navigation button:enabled:focus),
  :global(.react-calendar__tile:enabled:hover),
  :global(.react-calendar__tile:enabled:focus),
  :global(.react-calendar__tile--now:enabled:hover),
  :global(.react-calendar__tile--now:enabled:focus),
  :global(.react-calendar__tile--now) {
    background: var(--calendar-hover);
  }
  :global(.react-calendar__tile--active) {
    background: var(--bronze);
  }
  :global(.react-calendar) {
    font-family: "Libre Baskerville";
    font-size: 14px;
  }
  :global(
      .react-datetime-picker__button:enabled:hover
        .react-datetime-picker__button__icon
    ),
  :global(
      .react-datetime-picker__button:enabled:focus
        .react-datetime-picker__button__icon
    ) {
    stroke: var(--calendar-hover);
  }
  :global(.react-datetime-picker__inputGroup__input) {
    height: auto;
  }
  :global(.react-datetime-picker__wrapper) {
    align-items: center;
  }
  :global(.react-datetime-picker--disabled).textbox {
    opacity: 0.5;
    background-color: var(--input-bg);
  }
}

.incrementer {
  .wrap {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: right;
  }
  .number {
    font-weight: 600;
  }
  .icon {
    padding: 12px;
    // border-radius: 8px;
    border: 1px solid var(--default-border);
    line-height: 1;
  }
  button.icon {
    &:hover {
      background-color: var(--bronze);
      color: var(--pink);
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 16px;
  &.disabled {
    opacity: 0.5;
  }
  &.right {
    flex-direction: row-reverse;
  }
  &.labelAsHtml {
    align-items: flex-start;
    .label {
      width: 100%;
    }
  }
  .box {
    width: 20px;
    height: 20px;
    border: 1px solid var(--default-border);
    // border-radius: 3px;
    position: relative;
    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s;
      &.checked {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .label {
    padding-bottom: 0;
  }
}

.dropdownAsync {
  position: relative;
  text-align: left;
  select {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.cardDetails {
  .checkbox {
    align-items: flex-start;
    gap: 24px;
  }
  .cards {
    width: calc(100% - 20px);
    .item {
      .meta {
        display: flex;
        gap: 24px;
      }
      .img img {
        height: auto;
        width: 90px;
      }
      .detail {
        width: calc(100% - 90px);
        .text {
          & > span:first-child {
            display: inline-block;
            width: 40%;
            padding-right: 8px;
          }
          & > span:last-child {
            display: inline-block;
            width: 60%;
            padding-left: 8px;
          }
        }
      }
      .cvc {
        .label {
          padding-bottom: 8px;
        }
      }
    }
  }
}
