.title {
    color: var(--default-blue);
}

.titleWithButton {
    position: relative;
}

.backIcon {
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-75%);
}

.h1 {
    padding: 0 0 12px 0;
    border-bottom: 1px solid var(--border-bg);
}

.h1 {
    font-size: 32px;
}

.h4 {
    font-size: 18px;
}

@media (max-width:1400px) {
    .h1 {
        font-size: 30px;
    }

    .h4 {
        font-size: 16px;
    }
}

@media (max-width:1200px) {
    .h1 {
        font-size: 28px;
    }
}

@media (max-width:1024px) {
    .h1 {
        font-size: 26px;
    }
}

@media (max-width:820px) {
    .h1 {
        font-size: 24px;
    }
}