@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Baskerville/LibreBaskerville-Bold.eot');
    src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
        url('../fonts/Baskerville/LibreBaskerville-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baskerville/LibreBaskerville-Bold.woff2') format('woff2'),
        url('../fonts/Baskerville/LibreBaskerville-Bold.woff') format('woff'),
        url('../fonts/Baskerville/LibreBaskerville-Bold.ttf') format('truetype'),
        url('../fonts/Baskerville/LibreBaskerville-Bold.svg#LibreBaskerville-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Baskerville/LibreBaskerville-Regular.eot');
    src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
        url('../fonts/Baskerville/LibreBaskerville-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baskerville/LibreBaskerville-Regular.woff2') format('woff2'),
        url('../fonts/Baskerville/LibreBaskerville-Regular.woff') format('woff'),
        url('../fonts/Baskerville/LibreBaskerville-Regular.ttf') format('truetype'),
        url('../fonts/Baskerville/LibreBaskerville-Regular.svg#LibreBaskerville-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Baskerville/LibreBaskerville-Italic.eot');
    src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'),
        url('../fonts/Baskerville/LibreBaskerville-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Baskerville/LibreBaskerville-Italic.woff2') format('woff2'),
        url('../fonts/Baskerville/LibreBaskerville-Italic.woff') format('woff'),
        url('../fonts/Baskerville/LibreBaskerville-Italic.ttf') format('truetype'),
        url('../fonts/Baskerville/LibreBaskerville-Italic.svg#LibreBaskerville-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

