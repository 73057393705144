.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-y: auto;
  background-color: var(--modal-bg);
  color: var(--card-text);
  padding: 20px;
  transition: opacity 0.6s ease-in;
  opacity: 0;
}

.dialogBoxOverlay {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 1px);
}

.modal::after {
  content: "";
  height: 100%;
  width: 1px;
  display: inline-block;
  vertical-align: middle;
}

.dialogOpen {
  opacity: 1;
}

.dialogRemove {
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.dialogClose {
  position: fixed;
  right: 10px;
  top: 5px;
  padding: 15px 20px;
  background-color: var(--close-btn-bg);
  z-index: 2;
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 10px;
}

.closeIcon {
  font-size: 24px;
  line-height: 1;
  font-weight: 600;
}

.closeText {
  font-size: 16px;
  font-weight: bold;
}

.dialogClose:hover {
  color: var(--active-pink);
}

.booking {
  display: flex;
  justify-content: right;
  padding: 0;
  overflow: hidden;

  .wrap {
    width: 50%;
    height: 100%;
    background-color: var(--popup-bg);
    transform: translateX(200px);
    transition: 0.6s;
    overflow-y: auto;
    max-width: 750px;
    &.scrollDisabled {
      overflow: hidden;
    }
    @media(max-width: 1200px) {
      width: 65%;
    }
    @media(max-width: 1024px) {
      width: 80%;
    }
    @media(max-width: 740px) {
      width: 100%;
    }
  }
  &.dialogOpen .wrap {
    transform: translateX(0);
  }
  &.dialogRemove .wrap {
    transform: translateX(200px);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid var(--default-border);
    color: var(--card-title);
    .title {
      color: inherit;
    }
    @media  (max-width: 740px) {
      padding: 16px 24px;
    }
  }

  .dialogClose {
    position: static;
    border: var(--close-btn-border-width) solid var(--default-border);
    // border-radius: 8px;
    padding: 11px 14px;
    &:hover {
      background-color: var(--close-btn-hover-bg);
      color: var(--pink);
    }
  }
}

@media (max-width: 550px) {
  .closeText {
    display: none;
  }

  .dialogClose {
    right: 0;
    top: 0;
  }
}
