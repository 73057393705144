@import "../../../assets/scss/_fieldWrap.scss";
@import "../../../assets/scss/_adyen.scss";

.info {
    border-bottom: var(--special-border-width) solid var(--default-border);
}

.additionalWrab {
    padding: 0;
    .paymentMake{
        padding: 24px;
        @media (max-width:740px) {
            padding: 16px 24px;
          }
    }
}
.content {
    padding: 24px;
    background-color: var(--card-bg);
    border-bottom:1px solid var(--default-border) ;
    border-top:1px solid var(--default-border) ;
    @media (max-width:740px) {
        padding: 16px 24px;
      }
}
.additionWrab {
    padding: 0;
    .amountBox{
        border-bottom: 1px solid var(--default-border);
        padding: 24px;
    }
    .paymentBox{
        padding: 24px;
    }
}
.customWrap {
    background-color: var(--custom-popup-body-bg);
}