@import "../../assets/scss/_fieldWrap.scss";

.notifications {
  padding: 0;
  .header {
    background-color: var(--popup-header-bg);
    color: var(--pink);
    color: var(--popup-header-color);
    display: flex;
    justify-content: space-between;
    @media (max-width: 740px) {
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
    .col {
      padding: 24px;
      width: 20%;
      &.active {
        background-color: var(--popup-body-bg);
        color: var(--card-text);
      }
      @media (max-width: 740px) {
        width: auto;
        display: inline-block;
        padding: 16px;
      }
    }
  }
  .body {
    padding: 0 24px 24px;
    background-color: var(--popup-body-bg);
  
    @media (max-width: 740px) {
      padding-bottom: 16px;
    }
  }
  .item {
    padding: 24px;
    // border-radius: 16px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid var(--default-border);
    &.unread {
      background-color: var(--secondary-white);
      border-color: transparent;
    }
    .icon {
      &:hover {
        color: var(--pink);
      }
      i {
        font-size: 20px;
      }
    }
    @media (max-width: 740px) {
      padding: 16px;
    }
  }
  .skeleton {
    & > .item {
      position: relative;
      overflow: hidden;
      height: 110px;
      border-color: transparent;
    }
  }
}

.count {
  position: absolute;
  top: -5px;
  right: -15px;
  background-color: var(--count-bg);
  color: var(--count-color);
  padding: 5px;
  // border-radius: 3px;
  font-size: 10px;
  min-width: 20px;
}
