.underlined {
  text-decoration: underline;
  text-underline-offset: 5px;
  &:hover {
    text-decoration-color: transparent;
  }
}

.disabled {
  opacity: 0.5;
}
