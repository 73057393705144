.spacer {
  &.s24 {
    padding-top: 24px;
  }
  &.s16 {
    padding-top: 16px;
  }
  &.s10 {
    padding-top: 10px;
  }
}
