/* Form Start */

.fieldWrap {
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  // border-radius: 3px;
  margin-top: 30px;
  padding: 30px;
  background-color: var(--loading-bg);

  .Loader {
    width: 100%;
    // border-radius: 5px;
    height: 48px;
  }

  .textArea {
    height: 225px;
    margin-top: 15px;
  }
}

/* Form end */

.rectangleSpinner {
  // border-radius: 4px;
  background-image: none;
  overflow: hidden;
  background-color: var(--loading-bg);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: rectangleAnimation 1s linear infinite;
    background: var(--skeleton-bg);
  }
}

@keyframes rectangleAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }

  100% {
    transform: translate3d(30%, 0, 0);
  }
}
