.fieldWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  &.datepicker {
    position: relative;
    z-index: 2;
  }

  .field {
    width: 50%;
    text-align: right;
    > div {
      width: 200px;
      display: inline-block;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    &.full {
      > div {
        width: 100%;
        :global(.react-datetime-picker__inputGroup) {
          text-align: left;
        }
      }
    }
    @media (max-width: 600px) {
      text-align: left;
      width: 100%;
    }
  }
  .label {
    width: 50%;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .button {
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}
.wrap {
  padding: 24px;
  overflow-y: auto;

  @media (max-width: 740px) {
    padding: 16px 24px;
  }
}
.float {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 24px;
  background-color: var(--popup-bg);
  border-top: 1px solid var(--default-border);

  @media (max-width: 740px) {
    padding: 16px 24px 16px;
  }
}
.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    & > * {
      width: 100%;
    }
  }
}

.info {
  background-color: var(--card-bg);
  padding: 24px;

  @media (max-width: 740px) {
    padding: 16px 24px;
  }

  .desc {
    @media (max-width: 600px) {
      display: none;
    }
  }
}