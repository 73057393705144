.box {
    max-width: 600px;
    width: 60%;
    background-color: var(--white);
    padding: 36px 30px;
    // border-radius: 20px;
    margin: auto;
}

.title {
    color: var(--default-text);
}

.text {
    padding-top: 20px;
    font-size: 16px;
}

.button {
    margin-top: 30px;
}

.link {
    margin-top: 30px;
    min-width: 200px;
    background-color: var(--btn-primary);
    color: var(--white);
    border-color: var(--btn-primary);
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.03em;
    display: inline-block;
    text-align: center;
    border: 1px solid;
    // border-radius: 50px;
    padding: 15px 25px;
    font-weight: 600;
}

.link:hover{
    background-color: transparent;
    color: var(--btn-primary);
}

@media only screen and (max-width: 1200px) {
    .box {
        width: 70%;
    }
}

@media only screen and (max-width: 1024px) {
    .box {
        width: 80%;
    }
}

@media only screen and (max-width: 880px) {
    .box {
        width: 90%;
    }
}

@media only screen and (max-width: 550px) {
    .box {
        width: 100%;
    }
}