.section {
  position: relative;
  padding: 35px 0;

  @media (max-width: 820px) {
    padding: 30px 0;
  }
}

.secWhite + .secWhite {
  margin-top: -35px;

  @media (max-width: 820px) {
    margin-top: -30px;
  }
}
