.confirmPopup {
  max-width: 400px;
  margin: 0 auto;
  background-color: var(--confirm-popup-body-bg);
  border: var(--special-border-width) solid var(--special-border);
  padding: 24px;
  // border-radius: 4px;

  &.big {
    max-width: 780px;
  }

  .buttons {
    display: flex;
    gap: 24px;
    justify-content: space-between;

    @media (max-width: 420px) {
      &.fwrap {
        flex-wrap: wrap;
        gap: 16px;
        & > * {
          width: 100%;
        }
      }
    }
  }
}
