.container {
    text-align: center;
    padding: 20px 0;
}

.container>* {
    display: inline-block;
    width: 100%;
    margin-top: 0px;
}

.container .icon {
    font-size: 40px;
}

.container .icon svg {
    margin: auto;
}

.container .text {
    font-weight: 600;
    font-family: "opensans", sans-serif;
    margin-top: 10px;
}