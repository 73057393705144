:root {
  color-scheme: light only;
  /* default colors */
  --white: #fff;
  --blue: #2674B8;
  --secondary-blue: #135EAB;
  --teritary-blue: #0F3F7E;
  --dark: var(--teritary-blue);
  --primary-beige: #FFF9F1;
  --secondary-beige: #FFF5E7;
  --tertiary-beige: #F3E4D2;
  --green: #05AA4B;
  --secondary-white:var(--tertiary-beige);
  --default-text: var(--teritary-blue);
  --default-bg: var(--secondary-beige);

  --input-required: #FF0000;
  --input-valid: #00A13B;
  --toastify-color-light: var(--blue);
  --toastify-text-color-light: var(--secondary-beige);
  --placeholder: var(--blue);
  --modal-bg: rgb(38, 116, 184, 0.8);

  --header-bg: var(--blue);
  --header-profile-bg: var(--teritary-blue);
  --mobile-menu-bg: var(--blue);
  --mobile-menu-bg-hover: var(--default-bg);
  --header-profile-color: var(--primary-beige);
  --header-tab-color: var(--secondary-beige);
  --active-header-tab-color: var(--teritary-blue);
  --active-header-tab-bg: var(--green);

  --icon-color: var(--secondary-beige);
  --count-bg: var(--secondary-beige);
  --count-color: var(--blue);

  --section-bg: var(--secondary-blue);
  --section-color: var(--primary-beige);

  --tab-bg: var(--blue);
  --tab-color: var(--primary-beige);
  --active-tab-bg: var(--secondary-beige);
  --active-tab-color: var(--teritary-blue);
  --tab-title-color: var(--teritary-blue);
  --tab-border-color: var(--blue);
  --site-bgimg: var(--secondary-beige);

  --card-bg: var(--tertiary-beige);
  --card-title: var(--blue);
  --card-text: var(--teritary-blue);

  --account-title-text: var(--teritary-blue);
  --account-text: var(--teritary-blue);

  --border-pink: var(--secondary-blue);
  --secondary-border-pink: var(--secondary-blue);
  --editor-link-border: var(--teritary-blue);
  --editor-link-border-secondary: var(--teritary-blue);
  --default-border: var(--teritary-blue);
  --secondary-border: var(--blue);
  --accordion-border: var(--blue);
  --tertiary-border: var(--blue);
  --input-border: var(--teritary-blue);
  --special-border: var(--blue);
  --special-border-width: 1px;
  --close-btn-border-width: 1px;
  --card-content-border-width: 0px;

  --input-bg: transparent;
  --checkbox-border: var(--secondary-blue);
  --active-checkbox-bg: var(--blue);
  --active-checkbox-border: var(--secondary-blue);

  --btn-primary: var(--secondary-blue);
  --btn-secondary: var(--primary-beige);
  --btn-text: var(--primary-beige);
  --btn-gold: var(--secondary-blue);
  --btn-gold-text: var(--primary-beige);
  --btn-solid: var(--teritary-blue);
  --btn-solid-text: var(--blue);
  --active-checkbox-color:var(--secondary-beige);

  --popup-bg: var(--secondary-beige);
  --popup-body-bg: var(--secondary-beige);
  --confirm-popup-body-bg: var(--secondary-beige);
  --custom-popup-body-bg: transparent;
  --popup-header-bg: var(--blue);
  --popup-header-color: var(--secondary-beige);
  --close-btn-hover-bg: var(--green);
  --close-btn-bg: transparent;
  --switch-body-bg:var(--tertiary-beige);

  --secondary-pink:var(--secondary-beige);

  --loader: var(--teritary-blue);
  --load-more-bg: var(--tertiary-beige);

  --sub-section-bg: var(--teritary-blue);
  --sub-section-color: var(--blue);
  --intro-text: var(--teritary-blue);
  --static-footer-text: var(--teritary-blue);

  --adyen-dark: var(--tertiary-beige);
  --adyen-light: var(--teritary-blue);

  --loading-bg: var(--blue);
  --floating-footer-bg: var(--blue);
  --floating-footer-color: var(--teritary-blue);
  --floating-footer-active: var(--primary-beige);
  --skeleton-bg: linear-gradient(
      to right,
      rgba(38, 116, 184, 0.35) 46%,
      rgba(38, 116, 184, 0) 50%,
      rgba(38, 116, 184, 0.35) 54%
    )
    50% 50%;

  --spinner-color: var(--teritary-blue);
  --filter-bg: var(--tertiary-beige);
  --highlight-text-color: var(--input-valid);
  --calendar-hover: var(--blue);
  --no-text-color: var(--teritary-blue);
}

.fivehs {
    color-scheme: light only;
    --red: #6C2B32;
    --gold: #DABF91;
    --cream: #F7EADA;
    --dark: #000;
    --secondary-pink: var(--cream);
    --secondary-white: var(--gold);
    --pink: #E7CECE;
    --toastify-color-light: var(--gold);
    --toastify-text-color-light: var(--red);
    --secondary-bronze: #7C4949;
    --default-bg: var(--red);
    --close-btn-hover-bg: #62272E;
    --close-btn-bg: transparent;
    --header-bg: var(--red);
    --mobile-menu-bg: var(--red);
    --mobile-menu-bg-hover: var(--gold);
    --header-profile-bg: var(--gold);
    --header-profile-color: var(--red);
    --header-tab-color: var(--cream);
    --active-header-tab-color: var(--red);
    --active-header-tab-bg: var(--gold);
    --default-text: var(--red);

    --icon-color: var(--cream);
    --count-bg: var(--cream);
    --count-color: var(--red);
    --placeholder: var(--secondary-bronze);

    --tab-bg: var(--gold);
    --tab-color: var(--red);
    --active-tab-bg: var(--red);
    --active-tab-color: var(--cream);
    --tab-title-color: var(--cream);
    --tab-border-color: var(--gold);
    --site-bgimg: var(--red);

    --card-bg: var(--cream);
    --card-title: var(--red);
    --card-text: var(--red);

    --input-bg: var(--gold);
    --checkbox-border: var(--red);
    --active-checkbox-bg: var(--gold);
    --active-checkbox-border: var(--gold);
    --active-checkbox-color: var(--default-text);

    --account-title-text: var(--gold);
    --account-text: var(--red);

    --editor-link-border: var(--cream);
    --editor-link-border-secondary: var(--red);
    --default-border: var(--red);
    --secondary-border: var(--red);
    --accordion-border: var(--gold);
    --tertiary-border: var(--gold);
    --input-border: var(--red);
    --special-border: var(--gold);
    --border-pink: var(--gold);
    --secondary-border-pink: var(--red);
    --modal-bg: rgba(98, 39, 46, 0.9);
    --special-border-width: 1px;
    --card-content-border-width: 1px;

    --btn-primary: var(--red);
    --btn-secondary: transparent;
    --btn-gold: var(--gold);
    --btn-gold-text: var(--red);
    --btn-solid: var(--red);
    --btn-solid-text: var(--gold);

    --popup-bg: var(--gold);
    --popup-body-bg: var(--cream);
    --confirm-popup-body-bg: var(--cream);
    --custom-popup-body-bg: var(--cream);
    --popup-header-bg: var(--gold);
    --popup-header-color: var(--red);
    --switch-body-bg:var(--cream);

    --loader: var(--cream);
    --load-more-bg: var(--gold);

    --sub-section-bg: var(--gold);
    --sub-section-color: var(--red);
    --intro-text: var(--cream);
    --static-footer-text: var(--cream);

    --adyen-dark: var(--gold);
    --adyen-light: var(--default-text);

    --loading-bg: var(--gold);
    --floating-footer-bg: var(--red);
    --floating-footer-color: var(--cream);
    --floating-footer-active: var(--gold);
    --skeleton-bg: linear-gradient(to right,
            rgba(218, 191, 145, 0.35) 46%,
            rgba(218, 191, 145, 0) 50%,
            rgba(218, 191, 145, 0.35) 54%) 50% 50%;

    --spinner-color: var(--cream);
    --filter-bg: var(--gold);
    --highlight-text-color: var(--gold);
    --calendar-hover: var(--secondary-pink);
    --no-text-color: var(--cream);
}

.oswalds {
    color-scheme: light only;
    --grey: #546065;
    --cream: #F9EED6;
    --beige: #F9F2E8;
    --black: #000;
    --dark: #000;
    --midgrey: #ccc;
    --secondary-white: var(--cream);
    --secondary-pink: var(--beige);
    --sub-section-bg: var(--cream);
    --sub-section-color: var(--grey);
    --bronze: var(--grey);

    --default-text: var(--black);
    --default-bg: var(--cream);
    --modal-bg: rgba(84, 96, 101, 0.9);

    --toastify-color-light: var(--cream);
    --toastify-text-color-light: var(--black);
    --placeholder: var(--grey);
    --header-bg: var(--cream);
    --mobile-menu-bg: var(--cream);
    --mobile-menu-bg-hover: var(--cream);
    --header-profile-bg: var(--grey);
    --header-profile-color: var(--beige);
    --header-tab-color: var(--black);
    --active-header-tab-color: var(--black);
    --active-header-tab-bg: var(--beige);

    --icon-color: var(--black);
    --count-bg: var(--grey);
    --count-color: var(--beige);

    --tab-bg: var(--grey);
    --tab-color: var(--cream);
    --active-tab-bg: var(--cream);
    --active-tab-color: var(--black);
    --tab-title-color: var(--black);
    --tab-border-color: var(--grey);
    --site-bgimg: var(--cream);

    --card-bg: var(--beige);
    --card-title: var(--black);
    --card-text: var(--black);
    --card-content-border-width: 0px;

    --editor-link-border: var(--grey);
    --editor-link-border-secondary: var(--grey);
    --default-border: var(--grey);
    --secondary-border: var(--grey);
    --accordion-border: var(--grey);
    --tertiary-border: var(--gold);
    --input-border: var(--grey);
    --special-border: var(--grey);
    --border-pink: var(--grey);
    --secondary-border-pink: var(--grey);
    
    --account-title-text: var(--black);
    
    --special-border-width: 1px;
    --close-btn-border-width: 0px;
    --close-btn-hover-bg: var(--grey);
    --close-btn-bg: var(--beige);

    --active-checkbox-bg: var(--cream);
    --active-checkbox-border: var(--grey);
    --active-checkbox-color: var(--grey);

    --popup-bg: var(--cream);
    --popup-body-bg: var(--beige);
    --confirm-popup-body-bg: var(--cream);
    --popup-header-bg: var(--grey);
    --popup-header-color: var(--beige);
    --custom-popup-body-bg: var(--beige);
    --switch-body-bg:var(--beige);

    --btn-primary: var(--grey);
    --btn-secondary: transparent;
    --btn-gold: var(--grey);
    --btn-gold-text: var(--cream);
    --btn-solid: var(--grey);
    --btn-solid-text: var(--cream);

    --input-bg: var(--cream);

    --floating-footer-bg: var(--cream);
    --floating-footer-color: var(--black);
    --floating-footer-active: var(--beige);
    --loader: var(--cream);
    --load-more-bg: var(--cream);
    --skeleton-bg: linear-gradient(to right,
    rgba(84,96,101, 0.35) 46%,
    rgba(84,96,101, 0) 50%,
    rgba(84,96,101, 0.35) 54%) 50% 50%;

    --adyen-dark: var(--cream);
    --adyen-light: var(--grey);
    
    --loader: var(--grey);
    --filter-bg: var(--beige);
    --intro-text: var(--grey);
    --static-footer-text: var(--grey);
    --calendar-hover: var(--midgrey);
    --no-text-color: var(--black);
}

.Toastify__close-button--light {
    opacity: 1;
    color: var(--toastify-text-color-light);
}

.loggedIn {
    --toastify-toast-top: 185px;
}

* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    background: var(--default-bg);
    transition: background-color 0.4s ease-in-out;
    color: var(--default-text);
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
}

body,
input,
textarea,
select {
    font-family: "Libre Baskerville";
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

img {
    max-width: 100%;
    border-style: none;
}

p {
    line-height: 1.5;
}

label {
    cursor: pointer;
}

a {
    display: inline-block;
}

a,
button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
    font-family: inherit;
    font-size: inherit;
    background-color: transparent;
}

a:active {
    background-color: transparent;
}

select,
map,
area,
a,
button {
    border: 0px;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

input[type="text"],
input[type="password"],
textarea {
    text-align: left;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

ul,
li {
    list-style: none;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input::-ms-clear {
    display: none;
}

input[type="password"]::-ms-clear,
input[type="password"]::-ms-reveal {
    display: none;
}

input,
textarea,
select {
    border: 0;
    outline: none;
    line-height: normal;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    color: var(--default-text);
}

textarea {
    resize: none;
}

body>iframe {
    display: none !important;
}

.Toastify__toast-container--bottom-center {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
}

.Toastify__toast-container--bottom-center>div {
    margin-top: 10px;
}
